<template>
  <form>
    <slot />
  </form>
</template>

<script>
export const TwForm = {
  name: 'TwForm'
}

export { default as TwInput } from './TwInput'

export default TwForm
</script>
