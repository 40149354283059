<template>
  <div
    class="flex w-full h-full p-4 items-center justify-center bg-veturi-main text-white"
  >
    <div
      class="w-full h-5/6 -mt-10 sm:h-full
        grid grid-rows-2 place-items-center gap-10
        sm:grid-cols-2 sm:grid-rows-1 lg:w-10/12 lg:gap-20
      "
    >
      <veturi-logo
        class="flex text-white self-start h-4/5 sm:h-auto sm:p-0 sm:self-center sm:w-full max-w-full max-h-full"
      />
      <div class="flex flex-col p-10 w-full">
        <h1 class="mb-4 text-4xl font-extrabold">Login</h1>
        <form @submit.prevent="handleSubmit" class="flex flex-col">
          <tw-input
            label="E-mail"
            type="email"
            data-testid="email"
            class="mt-1 font-bold text-white"
            labelClass="block mb-1 text-lg"
            placeholder="joao@exemplo.com"
            v-model="state.email.value"
            :errorMessage="state.email.errorMessage"
          />
          <tw-input
            label="Senha"
            type="password"
            data-testid="password"
            class="mt-4 font-bold"
            labelClass="block mb-1 text-lg"
            placeholder="********"
            v-model="state.password.value"
            :errorMessage="state.password.errorMessage"
          />

          <button
            :disabled="!meta.valid"
            type="submit"
            id="login-button"
            class="flex h-12 w-full self-center items-center justify-center mt-10 text-2xl font-bold rounded focus:outline-none border-2 transition-all duration-150 text-veturi-dark bg-white focus:border-veturi-dark disabled:text-veturi-disabled disabled:opacity-95"
          >
            <icon v-if="state.isLoading" name="refresh" class="animate-spin" />
            <span v-else>Entrar</span>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue'
import { useField, useForm } from 'vee-validate'
import { useToast } from 'vue-toastification'
import VeturiLogo from '@/components/VeturiLogo'
import Icon from '@/components/Icon'
import { TwInput } from '@/components/TwForm'
import { validateEmpty, validateEmptyAndEmail } from '@/utils/validators'
import { auth } from '@/services'

export default {
  components: {
    VeturiLogo,
    TwInput,
    Icon
  },

  setup (props, { emit }) {
    const toast = useToast()

    const validationSchema = {
      email: validateEmptyAndEmail,
      password: validateEmpty
    }

    const { meta } = useForm({ validationSchema })

    const {
      value: emailValue,
      errorMessage: emailErrorMessage
    } = useField('email')

    const {
      value: passwordValue,
      errorMessage: passwordErrorMessage
    } = useField('password')

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      email: {
        value: emailValue,
        errorMessage: emailErrorMessage
      },
      password: {
        value: passwordValue,
        errorMessage: passwordErrorMessage
      }
    })

    async function handleSubmit () {
      if (meta.value.valid) {
        try {
          toast.clear()
          state.isLoading = true
          await auth.login({
            email: state.email.value,
            password: state.password.value
          })
        } catch (error) {
          toast.error(error.message)
        } finally {
          state.isLoading = false
        }
      }
    }

    return {
      state,
      meta,
      handleSubmit
    }
  }

}
</script>

<style scoped>
.logo {
  min-width: 300px;
}
</style>
