<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    :stroke="color"
    :width="size"
    :height="size"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M 20,4 V 9 H 19.418 M 4.062,11 A -8.001,8.001 0 0 1 19.418,9 m 0,0 H 15 M 4,20 v -5 h 0.581 m 0,0 A -8.003,8.003 0 0 0 19.938,13 M 4.581,15 H 9"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: { type: [String, Number], default: 24 },
    color: { type: String, default: 'currentColor' }
  }
}
</script>
