<template>
  <component :is="name" v-bind="$props"></component>
</template>

<script>
import Refresh from './Refresh'
import CurrencyDollar from './CurrencyDollar'
import ArrowLeft from './ArrowLeft'
import UserGroup from './UserGroup'
import Trash from './Trash'
import Save from './Save'

export default {
  components: {
    Refresh,
    CurrencyDollar,
    ArrowLeft,
    UserGroup,
    Trash,
    Save
  },
  props: {
    name: { type: String, required: true }
  }
}
</script>
