<template>
  <div>
    <label
      class="tracking-wide font-bold"
      v-if="!!label"
      :for="id"
      :class="labelClass"
    >
      <span>{{ label }}</span>
      <span
        class="pl-2 text-sm text-veturi-error"
        v-if="!!errorMessage"
        >{{ errorMessage }}</span
      >
    </label>
    <input
      class="w-full py-3 px-4 appearance-none leading-tight rounded font-bold bg-gray-100 border-2 focus:outline-none focus:bg-white text-veturi-dark"
      :id="id"
      :type="type"
      :value="modelValue"
      :placeholder="placeholder"
      @input="$emit('update:modelValue', $event.target.value)"
      :class="[borderColor, borderColorFocus]"
    />
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  props: {
    type: { type: String },
    modelValue: { type: String },
    id: { type: String },
    label: { type: String },
    labelClass: { type: String },
    placeholder: { type: String },
    errorMessage: { type: String }
  },
  setup (props) {
    const borderColor = computed(() =>
      props.errorMessage ? 'border-veturi-error' : 'border-gray-200'
    )

    const borderColorFocus = computed(() =>
      props.errorMessage ? 'focus:border-veturi-error' : 'focus:border-veturi-dark'
    )

    return {
      borderColor,
      borderColorFocus
    }
  }
}
</script>

<style scoped>
.input[disabled] {
  @apply bg-gray-300 border border-gray-400 font-bold;
}
</style>
