<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    :stroke="color"
    :width="size"
    :height="size"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M10 19l-7-7m0 0l7-7m-7 7h18"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: { type: [String, Number], default: 24 },
    color: { type: String, default: 'currentColor' }
  }
}
</script>
